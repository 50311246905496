import './style.scss';
import React from 'react';
import ym from 'react-yandex-metrika';
import NavigationLink from '../../atoms/navigation-link';
import ymTrigers from "../../../configs/ymTrigers";
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";
import cn from 'classnames';

type FooterState = {
    logo: {
        to: string,
        src: string,
        alt: string
    },
    nav: [
        {
            to?: string,
            href?: string,
            text: string
        }
    ],
    mail: string,
    tel: string,
    lang: {
        href?: string,
        text: string
    },
    copyright: string
}

const Footer = () => {
    // @ts-ignore
    const footer: FooterState = window.INIT_DATA;

    const metrika = () =>{
        ymTrigers.phoneNumberClick();
    }

    const renderLangLink = ({ className }: { className?: string }) => (
      footer.lang && typeof footer.lang.text === 'string' && (
        <a
            aria-label='change language'
            className={cn('footer__lang', className)}
            href={footer.lang.href}
        >
            {footer.lang.text}
        </a>
    ));

    if (!footer) return null;
    
    return (
        <footer className='footer'>
            <div className='footer__wrapper'>
                <div className='footer__controls'>
                    {footer.tel && typeof footer.tel === 'string' && <a className='footer__link' href={`tel:${footer.tel}`} aria-label='link phone number' onClick={() => metrika()}>{footer.tel}</a>}
                    {footer.mail && typeof footer.mail === 'string' && <a className='footer__link' aria-label='link mail to' href={`mailto:${footer.mail}`}>{footer.mail}</a>}
                    {renderLangLink({ className: 'footer__lang--in-controls' })}
                </div>
                <nav className='footer__nav'>
                    {footer.nav && !!footer.nav?.length && footer.nav.map((item, i) => (
                        <NavigationLink
                            className='footer__item'
                            to={fixedUrlTo(item.to)}
                            text={item?.text || ''}
                            key={`nav-${i}`}
                        />
                    ))}
                </nav>
                {renderLangLink({})}
                <span className='footer__copyright'>{`© 2006-${(new Date()).getFullYear()} Coalla Agency`}</span>
            </div>
        </footer>
    );
};

export default Footer;
