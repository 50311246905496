import './styles.scss';
import Heading from "../../molecules/heading";
import cn from 'classnames';
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useRef } from "react";

type TProps = {
    title: string
    items: Array<{
        img: string
        alt: string
    }>
    className?: string
}

gsap.registerPlugin(useGSAP);

const RunningLogos: React.FC<TProps> = ({
    className,
    title,
    items,
}) => {
    const itemsScrollRef = useRef<null | HTMLDivElement>(null);
    const refImages = useRef<null | HTMLDivElement>(null);

    const itemsList = items.map(({ img, alt }) => {
        return (
            <div key={img} className="running-logos__img-wrapper">
                <img className="running-logos__img" alt={alt} src={img} />
            </div>
        )
    });

    useGSAP(() => {
        const widthEl = refImages.current?.offsetWidth;
        
        if (widthEl) {
            const mm = gsap.matchMedia();
    
            mm.add('(max-width: 769px', () => {
                gsap.to('.running-logos__items', {
                    xPercent: -100,
                    duration: 32,
                    ease: 'none',
                    repeat: -1,
                });
            })
            mm.add('(min-width: 768px', () => {
                gsap.to('.running-logos__items', {
                    xPercent: -100,
                    duration: 52.12,
                    ease: 'none',
                    repeat: -1,
                });
            })
        }

    }, { scope: itemsScrollRef })

    return (
        <section className={cn('running-logos', className)}>
            {title && (
                <div className="running-logos__head-wrapper container">
                    <Heading
                        className="running-logos__head running-logos__head--r-shrink"
                        title={title}
                        theme="light"
                    />
                </div>
            )}
            {Array.isArray(items) && items.length && (
                <div className="running-logos__items-wrapper">
                    <div className="running-logos__items-scroll" ref={itemsScrollRef}>
                        <div ref={refImages} className="running-logos__items">
                            {itemsList}
                        </div>
                        <div className="running-logos__items">
                            {itemsList}
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default RunningLogos;
